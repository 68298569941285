/* eslint-disable */
import React, { Component } from 'react';
import IdleAlert from '../AlertTypes/Idle/IdleAlert';
import EngineOilLifeAlert from '../AlertTypes/EngineOilLife/EngineOilLifeAlert';
import FuelTankAlert from '../AlertTypes/FuelTank/FuelTankAlert';
import TirePressureAlert from '../AlertTypes/TirePressure/TirePressureAlert';
import ExternalPowerOnAlert from '../AlertTypes/PowerReconnect/ExternalPowerOnAlert';
import DevicePowerDisconnectAlert from '../AlertTypes/PowerDisconnect/DevicePowerDisconnectAlert';
import TemperatureAlert from '../AlertTypes/Temperature/TemperatureAlert';
import LowAssetBatteryAlert from '../AlertTypes/LowAssetBattery/LowAssetBatteryAlert';
// import LowDeviceBatteryAlert from '../AlertTypes/LowDeviceBattery/LowDeviceBatteryAlert';
import HardBrakingAccelerationAlert from '../AlertTypes/HardBrakingAcceleration/HardBrakingAccelerationAlert';
import OddHoursAlert from '../AlertTypes/OddHours/OddHoursAlert';
import SeatbeltAlert from '../AlertTypes/Seatbelt/SeatbeltAlert';
import PostedSpeedLimitAlert from '../AlertTypes/SpeedLimit/PostedSpeedLimitAlert';
import SpeedThresholdAlertList from '../AlertTypes/SpeedThreshold/SpeedThresholdAlertList';
import UnauthorizedMovement from '../AlertTypes/UnauthorizedMovement/UnauthorizedMovement';
import DtcAlert from '../AlertTypes/DtcAlert/DtcAlert';
import InputAlert from '../AlertTypes/Input/InputAlert';
import StopAlert from '../AlertTypes/Stop/StopAlert';
import LandmarkArrivalDepartureAlertList from '../../../containers/Alerts/AlertTypes/LandmarkArrivalDepartureAlertList';
import TamperAlert from '../AlertTypes/Tamper/TamperAlert';
import { ALERT, ALERT_DETAILS } from '../../../containers/Alerts/constants.alerts';

type Props = {
    alertType: String,
    formData: any,
    handleTextboxChange: Function,
    handleCheckboxChange: Function,
    clearLandmarkSelects: Function,
    validationError?: String,
};

class RightPanel extends Component<Props> {
    render() {
        const { alertType } = this.props;

        switch (alertType) {
        case ALERT.HARD_BRAKING_ACCELERATION_ALERT.CODE:
            return (
                <HardBrakingAccelerationAlert
                    formData={this.props.formData}
                    handleCheckboxChange={this.props.handleCheckboxChange}
                />
            );
        case ALERT.ODD_HOURS_ALERT.CODE:
            return (
                <OddHoursAlert
                    formData={this.props.formData}
                    handleCheckboxChange={this.props.handleCheckboxChange}
                    handleTextboxChange={this.props.handleTextboxChange}
                />
            );
        case ALERT.POSTED_SPEED_LIMIT_ALERT.CODE:
            return (
                <PostedSpeedLimitAlert
                    formData={this.props.formData}
                    handleTextboxChange={this.props.handleTextboxChange}
                    validationError={this.props.validationError}
                    handleCheckboxChange={this.props.handleCheckboxChange}
                />
            );
        case ALERT.SPEED_THRESHOLD_ALERT.CODE:
            return (
                <SpeedThresholdAlertList
                    formData={this.props.formData}
                    validationError={this.props.validationError}
                    handleTextboxChange={this.props.handleTextboxChange}
                    handleCheckboxChange={this.props.handleCheckboxChange}
                    clearLandmarkSelects={this.props.clearLandmarkSelects}
                />
            );
        case ALERT.UNAUTHORIZED_MOVEMENT.CODE:
            return <UnauthorizedMovement />;
        case ALERT.SEATBELT_STATUS_ALERT.CODE:
            return (
                <SeatbeltAlert
                    formData={this.props.formData}
                    handleTextboxChange={this.props.handleTextboxChange}
                    handleCheckboxChange={this.props.handleCheckboxChange}
                />
            );
        case ALERT.IDLE_ALERT.CODE:
            return (
                <IdleAlert
                    formData={this.props.formData}
                    handleTextboxChange={this.props.handleTextboxChange}
                    handleCheckboxChange={this.props.handleCheckboxChange}
                />
            );
        case ALERT.STOP_ALERT.CODE:
            return (
                <StopAlert
                    formData={this.props.formData}
                    handleTextboxChange={this.props.handleTextboxChange}
                />
            );
        case ALERT.INPUT_ALERT.CODE:
            return (
                <InputAlert
                    formData={this.props.formData}
                    handleCheckboxChange={this.props.handleCheckboxChange}
                />
            );
        case ALERT.LANDMARK_ARRIVAL_DEPARTURE_ALERT.CODE:
            return (
                <LandmarkArrivalDepartureAlertList
                    formData={this.props.formData}
                    handleTextboxChange={this.props.handleTextboxChange}
                    handleCheckboxChange={this.props.handleCheckboxChange}
                    clearLandmarkSelects={this.props.clearLandmarkSelects}
                />
            );
        case ALERT.EXTERNAL_DEVICE_POWER_ON_ALERT.CODE:
            return <ExternalPowerOnAlert />;
        case ALERT.DEVICE_POWER_DISCONNECT_ALERT.CODE:
            return <DevicePowerDisconnectAlert />;
        case ALERT.TEMPERATURE_ALERT.CODE:
            return (
                <TemperatureAlert
                    formData={this.props.formData}
                    handleTextboxChange={this.props.handleTextboxChange}
                />
            );
        case ALERT.FUEL_TANK_ALERT.CODE:
            return (
                <FuelTankAlert
                    formData={this.props.formData}
                    handleTextboxChange={this.props.handleTextboxChange}
                    handleCheckboxChange={this.props.handleCheckboxChange}
                />
            );
        case ALERT.TAMPER_ALERT.CODE:
            return <TamperAlert />;
        case ALERT.DTC_ALERT.CODE:
            return <DtcAlert />;
        case ALERT.LOW_ASSET_BATTERY_ALERT.CODE:
            return (
                <LowAssetBatteryAlert
                    formData={this.props.formData}
                    handleCheckboxChange={this.props.handleCheckboxChange}
                    handleTextboxChange={this.props.handleTextboxChange}
                />
            );
        // case ALERT.LOW_DEVICE_BATTERY_ALERT.CODE:
        //     return <LowDeviceBatteryAlert />;
        case ALERT.TIRE_PRESSURE_ALERT.CODE:
            return (
                <TirePressureAlert
                    formData={this.props.formData}
                    handleTextboxChange={this.props.handleTextboxChange}
                    handleCheckboxChange={this.props.handleCheckboxChange}
                />
            );
        case ALERT.ENGINE_OIL_LIFE_ALERT.CODE:
            return (
                <EngineOilLifeAlert
                    formData={this.props.formData}
                    handleTextboxChange={this.props.handleTextboxChange}
                    handleCheckboxChange={this.props.handleCheckboxChange}
                />
            );
        default:
            return <div>{ALERT_DETAILS.PLEASE_SELECT}</div>;
        }
    }
}

export default RightPanel;
